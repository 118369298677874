<template>
	<div>
		<div>
			<el-dialog title="新增员工标准工资" :close-on-click-modal="false"  :close-on-press-escape="false"  :visible.sync="dialogVisible" width="760px" @closed="onclosed">

				<div class="add-content"> 
					<div class="form">
						<div class="item-f">
							<div class="name requird">编号：</div>
							<div class="val">
								<div class="box-input">
									<div class="selebox">
										<template v-if="form_type == '新增'">
											<!-- <el-select :disabled="form_type == '编辑'" v-model="form.bianhao" placeholder="请输入搜索">
												<el-option v-for="item in bianhaoList" :key="item.value" :label="item.label"
													:value="item.value">
												</el-option>
											</el-select> -->


											<el-select filterable :filter-method="do_search_user" v-model="select_user_id"
												placeholder="请选择人员_姓名" @change="onchange_user">
												<el-option v-for="item in user_list" :key="item.id" :label="item.name" :value="item.id">
												</el-option>
											</el-select>

										</template>

										<template v-if="form_type == '编辑'">
											<el-input :disabled="true" v-model="user_name"></el-input>
										</template>
									</div>

									<div class="content-seacher" v-if="form_type == '编辑'">
										<!-- <span>姓名：郭菲菲</span> -->
										<span>部门：{{user.depart_title}}</span>
										<span>岗位：{{user.level6}}</span>
									</div>
									<div class="content-seacher" v-if="form_type == '新增'">
										<!-- <span>姓名：郭菲菲</span> -->
										<span>部门：{{select_user_info.depart_title}}</span>
										<span>岗位：{{select_user_info.level6}}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name requird">职称津贴：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.zhicheng_sal"></el-input>
								</div>
							</div>
						</div>

						<div class="item-f">
							<div class="name requird">钳电工：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.qdg_sal"></el-input>
								</div>
							</div>
						</div>

						<div class="item-f">
							<div class="name requird">倒班：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.db_sal"></el-input>
								</div>
							</div>
						</div>

						<div class="item-f">
							<div class="name requird">培训师津贴：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.pxs_sal"></el-input>
								</div>
							</div>
						</div>

						<div class="item-f">
							<div class="name requird">冶炼：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.yelian_sal"></el-input>
								</div>
							</div>
						</div>






						<div class="item-f">
							<div class="name requird">定额工资：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.dinge_sal"></el-input>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name requird">结算工资：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.jiesuan_sal"></el-input>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name requird">传票工资：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.chuanpiao_sal"></el-input>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name requird">基本工资：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.base_sal"></el-input>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name requird">岗位工资：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.gangwei_sal"></el-input>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name">岗位补助：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.gangwei_bz"></el-input>
								</div>
							</div>
						</div>
						<div class="item-f">
							<div class="name">其他固定工资：</div>
							<div class="val">
								<div class="box-input">
									<el-input clearable placeholder="请输入" v-model="form.other_sal"></el-input>
								</div>
							</div>
						</div>

					</div>
				</div>

				<div class="footer-btn">
					<span @click="submit">提交</span>
					<span @click="dialogVisible = false">取消</span>
				</div>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				form_type: '新增',
				dialogVisible: false,
				bianhaoList: [{
					value: '选项1',
					label: '黄金糕'
				}, ],
				form: {
					zhicheng_sal:'',
					qdg_sal:'',
					db_sal:'',
					pxs_sal:'',
					yelian_sal:'',


					userId: '',
					dinge_sal: '',
					jiesuan_sal: '',
					chuanpiao_sal: '',
					base_sal: '',
					gangwei_sal: '',
					gangwei_bz: '',
					other_sal: '',
				},
				user_name: '',
				user: {},
				
				user_list: [],
				
				select_user_id: '',
				select_user_info: {}
			}
		},
		methods: {
			init(row) {
				this.dialogVisible = true
				//this.$log('编辑数据', row)
				if (row) {
					this.form_type = '编辑'
					let data = row || {}
					this.form = {
						userId: data.userId,
						dinge_sal: data.dinge_sal,
						
						qdg_sal: data.qdg_sal,
						db_sal: data.db_sal,
						pxs_sal: data.pxs_sal,
						yelian_sal: data.yelian_sal,

						jiesuan_sal: data.jiesuan_sal,
						chuanpiao_sal: data.chuanpiao_sal,
						base_sal: data.base_sal,
						gangwei_sal: data.gangwei_sal,
						gangwei_bz: data.gangwei_bz,
						other_sal: data.other_sal,
						zhicheng_sal: data.zhicheng_sal,
						
					}
					if (row.user) {
						this.user_name = row.user.name
						this.user = row.user || {}
					}
				} else {
					this.form_type = '新增'
				}
			},
			onclosed() {
				this.form = {
					zhicheng_sal:'',
					qdg_sal:'',
					db_sal:'',
					pxs_sal:'',
					yelian_sal:'',


					userId: '',
					dinge_sal: '',
					jiesuan_sal: '',
					chuanpiao_sal: '',
					base_sal: '',
					gangwei_sal: '',
					gangwei_bz: '',
					other_sal: '',
				}
				this.user_name=''
				this.user={}
				this.select_user_id= ''
				this.select_user_info= {}
			},

			isShow() {
				this.dialogVisible = true
			},
			submit() {
				if (!this.form.userId ) {
					return alertErr('请选择员工')
				}
				if (!this.form.zhicheng_sal) {
					return alertErr('请输入职称津贴')
				}
				
				if (!this.form.dinge_sal) {
					return alertErr('请输入定额工资')
				}
				if (!this.form.jiesuan_sal) {
					return alertErr('请输入结算工资')
				}
				if (!this.form.chuanpiao_sal) {
					return alertErr('请输入传票工资')
				}
				if (!this.form.base_sal) {
					return alertErr('请输入基本工资')
				}
				if (!this.form.gangwei_sal) {
					return alertErr('请输入岗位工资')
				}
				// if (!this.form.gangwei_bz) {
				// 	return alertErr('请输入岗位补助')
				// }
				// if (!this.form.other_sal) {
				// 	return alertErr('请输入其他固定工资')
				// }

				this.$api("editEmpSalarySet", {
					...this.form,
				}, "post").then((res) => {
					// this.loading = false
					alert(res)
					if (res.code == 200) {
						this.$emit('confirm')
						this.dialogVisible = false;
					}
				});
			},


			do_search_user(value) {
				console.log('搜索用户 value', value)
				this.$api("getUserList", {
					keyword: value
				}, "get").then((res) => {
					//this.$log('搜索用户', res)
					this.user_list = res.data.list || []
				});
			},
			
			onchange_user(value) {
				console.log('切换用户', value)
				this.select_user_info = this.user_list.find(v => v.id == value) || {}
				this.form.userId = this.select_user_info.id;
				//数据回显
			}
			
		}
	}
</script>

<style scoped lang="less">
	.footer-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 25px;
		border-top: 1px solid #EEEEEE;

		span {
			font-size: 16px;
			width: 91px;
			height: 38px;
			border-radius: 4px 4px 4px 4px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0 10px;
			cursor: pointer;

			&:hover {
				opacity: 0.752;
			}

			&:nth-child(1) {
				background: #2373C8;
				color: #fff;
			}

			&:nth-child(2) {
				border: 1px solid #B8B8B8;
				color: #6A6A6A;
			}
		}
	}

	.content-seacher {
		display: flex;
		flex-wrap: wrap;

		span {
			width: 50%;
			margin-bottom: 15px;
			font-size: 14px;
			color: #333;
		}
	}

	.add-content {
		padding-left: 40px;
		padding-right: 50px;
		padding-bottom: 10px;

		.item-f {
			margin-bottom: 18px;
			display: flex;

			// align-items: center;
			.val {
				flex: 1;

				.el-select {
					width: 100%;
					margin-bottom: 18px;
				}
			}
		}

		.name {
			width: 118px;
			color: #333333;
			font-size: 14px;
			display: flex;
			justify-content: flex-end;
			padding-top: 7px;
			margin-right: 10px;
		}

		.requird {
			&::before {
				content: '*';
				display: block;
				color: red;
				margin-right: 3px;
			}
		}
	}
</style>